@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * 15) + px !important;
  font-size: $sizeValue + rem !important;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  
  &:hover {
    color: $white;
  }
  
  &:not(.dropdown-toggle):after {  // need to exclude .dropdown-toggle so the dropdown caret appears
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &:focus {
    box-shadow: none !important;
  }
  
  &:active {
    color: $white !important;
  }

  &:focus:not(:active)::after {
    animation: ripple 1s ease-out;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
