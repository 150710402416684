#defective-claim-wizard {
  .nav {
    margin-bottom: 10px;

    .nav-link {
      padding-top: 0;

      &.active {
        border-bottom: 3px solid $red;
      }
    }
  }
}