// Colors
$black: #000000;
$white: #ffffff;
$dark-gray: #6c757d;
$light-gray: #f2f2f2;
$gray: #b3b3b3;
$yellow: #f5a623;
$blue: #17a2b8;
$green: #28a745;
$red: #db011c;
$dark-red: #8f0112;

// Bootstrap overrides
$primary: $red;
$grid-gutter-width: 10px;
$enable-rounded: false;
$carousel-control-color: $black;

// Custom
$nav-height: 84px;
$line-height-base: 1.5 !default;