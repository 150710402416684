#cart {
  .cart-list {
    .cart-list-item {
      border-right: none;
      border-bottom: none;
      border-left: none;

      &:first-child {
        border-top: none;
      }
      
      &:hover {
        z-index: 0;
      }
    }
    .cart-list-item-grid {
      border-right: none;
      border-bottom: none;
      border-left: none;
      padding: 0;

      &:first-child {
        border-top: none;
      }

      &:hover {
        z-index: 0;
      }
    }
  }

  .order-line-preview {
    img {
      max-height: 150px;
      max-width: 100%;
    }

    input[type="text"] {
      width: 4em;
      text-align: center;
    }
  }

  .order-line-preview-grid {
    input[type="text"] {
      width: 4em;
      text-align: center;
    }
    
    .product-data{
      width: 120px;
    }
    
    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    .order-line-preview {
      .product-image {
        width: 160px;
      }
    }
  }
}