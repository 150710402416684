#home {
    img {
        &.advertisement {
            max-width: 100%;
            margin: 10px 0;
        }
    }

    .overview-card {
        border-left: 3px solid $primary;

        .overview-sections {
            display: flex;
            justify-content: center;

            .overview-section {
                display: flex;
                flex-direction: column;
                padding: 0 1em;

                &:hover {
                    cursor: pointer;
                }

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 35px;
                        padding: 0 10px;
                    }
                }

                > span {
                    text-align: center;
                }
            }
        }
    }

    .announcement-item {
        cursor: pointer;
        user-select: none;
    }

    .info-card {

        .header-primary {
            display: flex;
            align-items: center;
            border-left: 3px solid $primary;
        }

        .header-secondary {
            display: flex;
            align-items: center;
            border-left: 3px solid $secondary;
        }

        .tiles {
            text-align: center;

            .tile {
                padding: 1.5rem 0;

                &:nth-child(even) {
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                }

                &:last-child {
                    border-right: none;
                    border-bottom: none !important;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }

                .icon {
                    color: $primary;
                    font-size: 3em;

                    svg {
                        width: 48px;
                        fill: $primary;
                    }
                }

                .icon-link {
                    position: absolute;
                    top: .5rem;
                    right: .5rem;
                    color: $secondary;
                }
            }
        }
    }

    #whats-new {
        .slick-slider {
            img {
                max-height: 10rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
  #home {
    .info-card {
      .tiles {
        .tile {
          border-right: none;
          border-bottom: none !important;
          //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          
         
            &:nth-child(even) {
                border-left: 1px solid rgba(0, 0, 0, 0.125);
                border-right: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
      }
    }
  }
}