@keyframes bottomFadeIn {
  from {
    transform: translateY(45px);
    opacity: 0;
  }
  to   {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-bottom-fade-in {
  animation: bottomFadeIn 300ms ease-in-out;
}

@keyframes fadeIn{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 300ms ease-in-out;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
  }
}

.animate-pulse {
  animation: pulse 1.5s infinite;
}