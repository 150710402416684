.filter-menu {
  .filter-menu-list {
    .list-group-item {
      border-right: none;
      border-bottom: none;
      border-left: none;
      cursor: pointer;

      &:first-child {
        border-top: none;
      }

      &:hover {
        z-index: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .filter-menu {
    .filter-menu-overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: $nav-height;
      left: -100%;
      opacity: 0;
      z-index: 1;
      content: '';
      background-color: rgba(0, 0, 0, .5);
      transition: left 0s, opacity 300ms linear;
    }

    .filter-menu-list {
      width: 280px;
      height: calc(100% - #{$nav-height}) !important;
      padding: 0 1rem;
      position: fixed;
      top: $nav-height;
      left: -280px;
      z-index: 50;
      background-color: $white;
      box-sizing: border-box;
      transition: transform 250ms linear;
      will-change: transform;
      overflow-y: scroll;
      
      &.show {
        transform: translatex(280px);

        & + .filter-menu-overlay {
          left: 0;
          opacity: 1;
        }
      }
      
      > .container {
        padding: 1rem 0;
      }
    }
  }
}