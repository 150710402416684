﻿#navbar {
  background-color: $primary;
  height: $nav-height;

  &.navbar {
    box-shadow: 0 0 15px 0 $dark-gray;
  }

  .navbar-brand {
    width: auto;
    height: 100%;
    padding: 0;
    margin-right: .75rem;
  }

  .nav-link {
    margin: 0 15px;
    letter-spacing: 1px;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    
    .nav-link-icon {
      height: 26px !important;
      
      .injected-svg {
        width: 1.125em;
        height: 1em;
      }
    }
    
    .nav-link-label {
      margin-top: 3px;
    }
  }

  .navbar-collapse {
    padding: 1rem;
    box-sizing: border-box;
    background-color: $primary;
  }
  
  .nav-right {
    .nav-link {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  #navbar {
    &.navbar {
      box-shadow: none !important;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: inline-block;
        box-shadow: 0 -7px 10px 10px rgba(0, 0, 0, 0.4);
        z-index: -1;
      }

      .navbar-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: $nav-height;
        left: -100%;
        opacity: 0;
        z-index: -2;
        content: '';
        background-color: rgba(0, 0, 0, .5);
        transition: left 0s, opacity 300ms linear;
      }

      .navbar-collapse {
        width: 280px;
        height: 100% !important;
        position: fixed;
        top: $nav-height;
        right: -280px;
        z-index: -1;
        background-color: $white;
        transition: transform 250ms linear;
        will-change: transform;

        &.show {
          height: 100% !important;
          transform: translateX(-280px);

          & + .navbar-overlay {
            left: 0;
            opacity: 1;
          }
        }

        .nav-item {
          &.active {
            .nav-link {
              color: $dark-gray;
            }
          }

          .nav-link {
            color: $primary;
          }

          .dropdown-menu {
            border: none;
            padding-top: 0;
            position: static !important;
            transform: none !important;

            .dropdown-item {
              color: $primary;
            }
          }
        }
      }

      .nav-right {
        .nav-link {
          margin: 0 10px;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  #navbar {
    &.navbar {
      .nav-right {
        .nav-link {
          margin: 0 4px;
        }
      }
    }
  }
}