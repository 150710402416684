.quantity-input {
  flex-wrap: nowrap;
  
  input {
    &.form-control {
      width: 3.4em !important;
      text-align: center;
    }

    /*remove the spinner from firefox*/
    &[type=number] {
      -moz-appearance: textfield;
    }
    /*remove the numeric spinner in chrome and safari*/
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; /* Removes leftover margin */
    }
  }
  
  button {
    border-color: $input-border-color;
    background-color: $white;
    color: $dark-gray;
  }
  
  /*opera do not right align numbers*/
  html:not([dir="rtl"]) input {
    text-align: left;
  }
}