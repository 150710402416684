footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background-color: #222222;
  color: white;

  .countries {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline;
      padding: 0 5px;
      cursor: pointer;
    }
  }
}

.footer-menu-link {
  display: inline-block;
  margin: 0 20px;
  position: relative;

  &:before {
    content: "|";
    left: -10px;
    position: absolute;
    color: #666;
  }
}