@import '~slick-carousel/slick/slick.scss';

.slick-slider {
  padding: 2rem 0;
  
  .slick-track {
    display: flex;
    
    .slick-slide {
      height: auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      
      > div {
        height: 100%;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
      img {
        max-width: 100%;
        margin: auto;
      }
      
      h6 {
        margin-top: 1rem;
      }
    }
  }

  .slick-dots {
    padding: 0;
    margin-top: 2rem;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    list-style-type: none;

    &:not(.slick-thumb) {
      height: 5px;

      > li {
        width: 40px;
      }
    }

    > li {
      margin: 0 3px;
      position: relative;
      flex: 0 1 auto;

      &.slick-active {
        > button {
          &::before {
            background-color: $carousel-control-color;
          }
        }
      }

      > button {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        overflow: hidden;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $gray;
          opacity: 0.5;
        }
      }
    }
  }
}

button.slick-arrow {
  width: 40px;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  display: inline-block !important;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  z-index: 1;

  :focus {
    outline: none !important;
  }

  &::before {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 10px;
    position: absolute;
    top: 50%;
    display: block;
    opacity: 0.5;
    content: '';
    background-size: cover;
  }
  
  &.slick-prev {
    left: 0;
    
    &::before {
      background-image: $carousel-control-prev-icon-bg;
    }
  }
  
  &.slick-next {
    right: 0;
    
    &::before {
      background-image: $carousel-control-next-icon-bg;
    }
  }
}