.icon {
  position: relative;

  .icon-badge {
    &::after {
      width: auto;
      height: auto;
      padding: .2rem .3rem;
      content: attr(data-count);
      position: absolute;
      top: -1em;
      right: -.9em;
      background-color: $blue !important;
      color: $white !important;
      text-align: center;
      border-radius: 25%;
      font-size: .6em;
      font-weight: bold;
    }
  }
}

.fa-2lg {
  font-size: 1.65em;
}

.fa-dim {
  opacity: 0.3;
}