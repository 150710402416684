$hamburger-types: (spring);
$hamburger-layer-width: 35px;
$hamburger-hover-opacity: 1.0;
$hamburger-layer-color: $white;

@import '~hamburgers/_sass/hamburgers/hamburgers.scss';

.hamburger {
  padding: 0;
  
  &:focus {
    outline: none !important;
  }
  
  .hamburger-inner {
    top: 5px !important;
  }
}