@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';

.quill {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
  &:focus {
    border-color: #db011c;
    box-shadow: 0 0 0 0.2rem rgba(219, 1, 28, 0.25);
  }
  
  .ql-editor {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      div & {
        border-color: #db011c;
        box-shadow: 0 0 0 0.3rem rgba(219, 1, 28, 0.25);
      }
    }
  }
  
  &.invalid {
    .ql-toolbar {
      border-bottom-color: #db011c;;
    }
    
    .ql-container {
      border-color: #db011c;
    }
  }
}