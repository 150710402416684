#warranty-search {
  .nav {
    margin-bottom: 10px;

    .nav-link {
      padding-top: 0;

      &.active {
        border-bottom: 3px solid $red;
      }
    }
  }
  
  .advanced-search-toggle {
    cursor: pointer;
  }

  .pagination {
    margin-top: 1rem;
    justify-content: center;
  }
  
  .warranty-preview {
    .warranty-info {
      &.label {
        text-transform: uppercase;
        font-size: 11px!important;
        line-height: 1.465!important;
        color: #555!important;
      }

      &.value {
        font-size: 13px!important;
        line-height: 19px!important;
        font-weight: 500;
      }

      &.status {
        font-size: 1.25rem;
        margin: 0;
      }
    }
  }
}