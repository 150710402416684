@import '~bootstrap/scss/bootstrap.scss';

.btn-primary, .btn-outline-primary {
  &:not(.is-loading) {
    @include ripple();
  }
}

.btn-secondary, .btn-outline-secondary {
  &:not(.is-loading) {
    @include ripple();
  }
}

.btn {
  position: relative;

  &.disabled, &:disabled {
    opacity: .3;
    pointer-events: none;

    &.btn-outline-primary {
      &:hover {
        color: $primary;
      }
    }

    &.btn-outline-secondary {
      &:hover {
        color: $secondary;
      }
    }
  }

  &.is-loading {
    background-color: $primary;
    color: transparent !important;
    pointer-events: none;

    &:after {
      position: absolute !important;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      animation: spinAround .5s infinite linear;
      border: 2px solid $white;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      opacity: 1;
    }
  }
}

input.form-control {
  &:disabled {
    opacity: .3;
    background-color: #fff;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;

  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 !important;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.075);
  margin: $grid-gutter-width 0;

  .card-header {
    background-color: #fff;
  }
  
  &.card-body {
    padding: 1rem;
  }
}

.icon.icon-avatar {
  width: 34px;
  height: 34px;
}

.breadcrumb {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.jumbotron {
  margin-bottom: 0;
}

.modal {
  .modal-body {
    img {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(md){
  .card {
    &.card-body {
      padding: 1rem .5rem;
    }
  }
}