// Colors
.is-color {
  &-black {color: $black;}
  &-gray {color: $gray;}
  &-dark-gray {color: $dark-gray;}
  &-yellow {color: $yellow;}
  &-blue {color: $blue;}
  &-green {color: $green;}
  &-red {color: $red;}
  &-white {color: $white;}
}

// Borders
.has-border {
  &-black {border-color: $black;}
  &-gray {border-color: $gray;}
  &-dark-gray {border-color: $dark-gray;}
  &-yellow {border-color: $yellow;}
  &-blue {border-color: $blue;}
  &-green {border-color: $green;}
  &-red {border-color: $red;}
  &-white {border-color: $white;}
  
  border-width: 1px;
  border-style: solid;
}

.has-pointer-icon {
  cursor: pointer;
}

// Backgrounds
.has-background {
  &-white {background-color: $white !important;}
  &-gray {background-color: $gray !important;}
  &-red {background-color: $red !important;}
  &-light-gray {background-color: $light-gray !important}
}

.has-background-banner-1 {
  background-color: $black !important;
  color: $white;
  text-align: center;
  position: relative;
  z-index: 1;
  
  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-image: $banner-1;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
  }
  
  h1 {
    margin-bottom: 2rem;
    color: $white;
  }
}

// Alignment
.has-justified-content {
  display: flex;
  justify-content: space-between !important;
}

// Fields/Tags/Buttons
.is-mock-link {
  color: $red;
  
  &:hover {
    color: $dark-red;
    text-decoration: underline;
    cursor: pointer;
  }
}

.is-static-link {
  text-decoration: underline;
  
  &:hover {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}

.is-inline-icon {
  &:not(:last-child) {
    margin-right: .75rem;
  }
}

.is-increment-button-group {
  input {
    border-top: 1px solid $dark-gray !important;
    border-bottom: 1px solid $dark-gray !important;
    
    &.form-control {
      width: 4em;
      text-align: center;
    }
    
    &:focus {
      box-shadow: none;
    }
  }
}

// Text
.has-font-size-0 {
  @include font-size(.5);
}

.has-font-size-1 {
  @include font-size(.75);
}

.has-font-size-2 {
  @include font-size(.9);
}

.has-font-size-3 {
  @include font-size();
}

.has-font-size-4 {
  @include font-size(1.1);
}

.has-font-size-5 {
  @include font-size(1.25);
}

.has-font-size-6 {
  @include font-size(1.5);
}

.has-line-height-0 {
  line-height: 0 !important;
}

.has-line-height-1 {
  line-height: ($line-height-base * .6) + 0rem !important;
}

.has-line-height-2 {
  line-height: ($line-height-base * .8) + 0rem !important;
}

.has-line-height-3 {
  line-height: $line-height-base + 0rem !important;
}

.has-line-height-4 {
  line-height: ($line-height-base * 1.25) + 0rem !important;
}

.wrap-break-words {
  word-wrap: break-word;
}

.no-wrap {
  white-space: nowrap;
}

.no-underline {
  text-decoration: none !important;
}

.white-space {
  &-normal {white-space: normal !important;}
  &-nowrap {white-space: nowrap !important;}
}