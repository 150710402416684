#product-details {
  margin-top: 1rem;
  
  .product-thumbs {
    .slick-thumb {
      max-height: 80px;
      
      > li {
        width: 16.67%;
        border: 1px solid #d6d6d6;
        cursor: pointer;

        &.slick-active {
          border: 1px solid $primary;
        }
        
        > div {
          width: 100%;
          height: 100%;
          text-align: center;
          
          > img {
            max-width: 100%;
            max-height: 100%;
            flex: 0;
          }
        }
      }
    }
  }
  
  .related-products {
    a {
      text-decoration: none;
    }
    
    .slick-slide {
      margin: 0 0.5rem;

      @include media-breakpoint-up(sm) {
        margin: 0 2rem;
      }
      
      > div {
        > a {
          min-height: 100%;
          height: 100%;

          > div {
            min-height: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}