.stepper {
  display: block;
  justify-content: center;
  
  .stepper-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .step {
    display: flex;
    overflow: visible;
    align-items: center;
    outline: 0;
    position: relative;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
    justify-content: center;
    
    &.active {
      .stepper-circle {
        @extend .animate-pulse;
        background-color: $blue;
      }
      .stepper-label {
        color: $black;
      }
    }
    
    &.complete {
      .stepper-circle {
        background-color: $success;
        color: $white;
      }
      .stepper-label {
        color: $black;
      }
    }
    
    &.small {
      .stepper-circle {
        width: 15px;
        height: 15px;
        padding: 0;
      }
    }

    .step-trigger {
      text-align: center;
      text-decoration: none;
      padding: 24px;
      overflow: visible;
      
      &:focus {
        text-decoration: none;
      }
    }
  }

  .stepper-label {
    font-weight: 600;
    color: $light-gray;
    display: inline-block;
  }
  
  .stepper-circle {
    margin-right: auto;
    margin-left: auto;
    background: lighten($primary, 50%);
    background: $light-gray;
    color: white;
    padding: 12px;
    border-radius: 50%;
    display: block;
    width: 50px;
  }

  .line {
    border-top: 1px dashed rgba(0, 0, 0, 0.12);
    flex: auto;
    height: 0;
    min-width: 20px;
    max-width: 80px;
  }
}

@include media-breakpoint-down(xs) {
  .stepper:not(.vertical) {
    display: flex;
    
    .stepper-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .stepper-content {
      padding: 0 0 0 48px;
    }
    
    .step {
      width: 100%;
      padding: 0 12px;

      &.small {
        .stepper-circle {
          margin-left: -10px;
        }
      }

      .stepper-circle {
        margin-right: 12px;
        margin-left: 0;
        display: inline-block;
      }
    }

    .line {
      height: 20px;
      min-height: 20px;
      width: 0;
      min-width: 0;
      border-top: none;
      border-left: 1px dashed rgba(0, 0, 0, 0.12);
      margin-right: auto;
      margin-left: auto;
    }
  }
}