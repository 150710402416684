#warranty-claim-wizard {
  .nav {
    margin-bottom: 10px;

    .nav-link {
      padding-top: 0;

      &.active {
        border-bottom: 3px solid $red;
      }
    }
  }

  .search-list {
    .search-list-item {
      img {
        max-width: 100%;
        max-height: 8rem;
      }
    }
  }

  .clear-button {
    padding-right: 1rem;
    padding-left: 1rem;
    color: $dark-gray !important;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    z-index: 10;
  }
}

@media print {
    #warranty-claim-stepper, #warranty-claim-links, #action-buttons, #review-header, footer {
        display: none;
    }

    div#warranty-claim-wizard {
        font-size: 12px;
        clear: both;
    }

    .main {
        margin-top: 0;
    }

    .card-body, .card-header {
        padding: 0.25rem;
        font-size: .50em;
    }

    .form-control, .input-group-text, .input-group, .input-group-prepend {
        font-size: 12px;
        border: none;
        padding: 0;
    }

    .input-group, .input-group-prepend  {
        max-width: 50%;
        max-height: 50%;
    }

    .react-select-container{
        width: 5rem;
    }

    .input-group-prepend {
        font-size: 12px;
        max-height: 50%;
    }

}