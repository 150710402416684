// Site-wide generic element styling
body {
  color: #5a5a5a;
  background-color: #fafafa !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body, #root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#root {
  min-height: 100vh;
}

.main {
  margin-top: $nav-height;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-wrapper {
  margin-bottom: 2rem;
}

input::-ms-clear {
  display: none;
}

.anticon {
    vertical-align: 0.125em !important;
}

.help-button {
  margin-left: .75rem;
  color: $dark-gray !important;
  background: transparent;
  cursor: pointer;
}

.invalid-order-tooltip {
    width: 275px;
    max-width: 275px !important;
}