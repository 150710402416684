@import '~swagger-ui-react/swagger-ui.css';

.swagger-ui {
    flex: 1 !important;

    .info {
        display: none;
    }

    .wrapper {
        max-width: 100%;
    }

    .models {
        display: none;
    }
}
