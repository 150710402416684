#order-details {
  .order-details-list {
    .order-details-list-item {
      border-right: none;
      border-bottom: none;
      border-left: none;

      &:first-child {
        border-top: none;
      }

      &:hover {
        z-index: 0;
      }
    }
  }
}