.orders-icon {
  color: $green;
}

.statements-icon {
  color: $blue;
}

.claims-icon {
  color: $yellow;
}

.cart-icon {
  color: $black;
}

.announcements-icon {
  color: $blue;
}

.notifications-icon {
  color: $yellow;
}