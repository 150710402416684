#products-admin {
  .search-list {
    .search-list-item {
      img {
        max-width: 100%;
        max-height: 8rem;
      }
    }
  }
  
  .pagination {
    margin-top: 1rem;
    margin-bottom: 0;
    justify-content: center;
  }
}