#warranty-claim-details {
  .freight-allowance, .hours {
    width: 8em;
  }
  
  table.parts-list-table {
    th {
      font-size: .9em;
      white-space: nowrap;
    }
    
    td {
      vertical-align: middle;
    }
    
    th, td {
      &.min-width {
        width: 1px;
      }
      
      &.description-column {
        width: 200px;
      }
      &.select-column {
        min-width: 200px;
      }
    }
    
    .quantity-input {
      .form-control {
        width: 3.5em;
        max-width: 3.5em;
      }
    }
  }
  .photos img {
    max-height: 150px;
    max-width: 100%;
  }
  .header-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .actions-menu {
    color: black;
  }
  .actions-menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions-option {
    padding: 0px 15px;
    cursor: pointer;
  }
}

@include media-breakpoint-down(md) {
  #warranty-claim-details {
    table.parts-list-table {
      table-layout: fixed;
      background: transparent;
      margin-bottom: 0;
      width: 100%;

      thead {
        display: none;
      }

      tr {
        background: #fff;
        border: 1px solid #dfdfdf;
        cursor: pointer;
        display: block;
        padding: 15px 10px;

        &:not(:last-child) {
          border-bottom: none;
        }
      }

      td, th {
        border: 0;
        display: block;
        padding: 0px 0px 20px 40%;
        position: relative;

        &:before {
          color: rgba(0, 0, 0, 0.35);
          text-transform: uppercase;
          font-size: .85em;
          content: attr(data-label);
          display: table-cell;
          font-weight: 500;
          height: 100%;
          left: 15px;
          margin: auto;
          position: absolute;
          vertical-align: middle;
          white-space: nowrap;
          width: 40%;
        }
        
        &.min-width {
          width: auto;
        }
        
        &.description-column {
          max-width: 100%;
        }
        
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

@media print {
  body {
    font-size: 22px;
  }

  .print-left-margin {
    left: -30px;
  }

  #warranty-claim-details {
      table.parts-list-table {
          td, th {
              -webkit-padding-after: 0;
              padding-bottom: 0;
          }
      }
  }
}