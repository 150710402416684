#faq-container {
  .faq-item {
    .faq-item-first {
      width: 25px;
    }
    
    ol.answer-list {
      padding-left: 1rem;
    }
  }
}