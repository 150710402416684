/**
* @name common-theme-light.less
* @desc less file for light theme styles
*/
/* Convert to CSS using an online tool */
/* Color Palette */
/*
	Red Theme Color Overrides
*/
.cx-widget.cx-theme-red {
  color: #000000;
  background-color: #FDFDFD;
  scrollbar-face-color: #CCCCCC;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* WCAG: non-text contrast for UI components - primary button focus state */
  /* loader icon */
}
.cx-widget.cx-theme-red #cx_webchat_form_subject {
  font-size: 0.68em;
  line-height: 0.8em;
}
.cx-widget.cx-theme-red .cx-widget svg:not(:root) {
  margin-top: -12px;
}
.cx-widget.cx-theme-red .cx-widget .cx-form-control {
  padding: 3px;
}
.cx-widget.cx-theme-red .cx-button-group .cx-svg-icon-tone1 {
  fill: #FDFDFD !important;
}
.cx-widget.cx-theme-red .cx-titlebar .cx-svg-icon-tone1 {
  fill: #FDFDFD !important;
}
.cx-widget.cx-theme-red .cx-titlebar {
  background-color: #db011c !important;
}
.cx-widget.cx-theme-red .cx-svg-icon-tone1 {
  fill: #FDFDFD;
}
.cx-widget.cx-theme-red .cx-theme {
  background-color: #FDFDFD;
  color: #000000;
  border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-red .cx-overlay {
  background-color: #FDFDFD;
}
.cx-widget.cx-theme-red .cx-svg-icon-tone1 {
  fill: #FF4F1F;
}
.cx-widget.cx-theme-red .cx-svg-icon-tone2 {
  fill: #8C8C8C;
}
.cx-widget.cx-theme-red .cx-svg-icon-shadow1 {
  fill: #000000;
  opacity: 0.2;
}
.cx-widget.cx-theme-red .cx-svg-icon-shadow2 {
  fill: #000000;
  opacity: 0.1;
}
.cx-widget.cx-theme-red * {
  border-color: #DAE1E8;
}
.cx-widget.cx-theme-red .cx-titlebar .cx-title {
  color: #FDFDFD;
  margin-top: -4px;
}
.cx-widget.cx-theme-red .cx-buttons-window-control .cx-svg-icon-tone1 {
  fill: #8795A6;
}
.cx-widget.cx-theme-red .cx-input-icon-overlay .cx-svg-icon-tone1 {
  fill: #98A7B8;
}
.cx-widget.cx-theme-red .cx-input-group-btn .cx-svg-icon-tone1 {
  fill: #FDFDFD;
}
.cx-widget.cx-theme-red label {
  color: #000000;
}
.cx-widget.cx-theme-red a {
  color: #000000;
}
.cx-widget.cx-theme-red a:hover {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-icon-alert-circle {
  color: #F53131;
}
.cx-widget.cx-theme-red .cx-footer,
.cx-widget.cx-theme-red .cx-branding {
  color: #66759B;
}
.cx-widget.cx-theme-red .cx-footer *,
.cx-widget.cx-theme-red .cx-branding * {
  fill: #66759B;
}
.cx-widget.cx-theme-red .cx-form-control.cx-error {
  border-color: #F53131;
}
.cx-widget.cx-theme-red .cx-form-control::placeholder {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-form-control:-moz-placeholder {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-form-control::-moz-placeholder {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-form-control:-ms-input-placeholder {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-form-control::-webkit-input-placeholder {
  color: #000000;
}
.cx-widget.cx-theme-red input:focus,
.cx-widget.cx-theme-red textarea:focus,
.cx-widget.cx-theme-red .cx-btn:focus,
.cx-widget.cx-theme-red .cx-button-group button:focus,
.cx-widget.cx-theme-red .cx-form-control:not(.cx-error):focus {
  border-color: #5791FD !important;
}
.cx-widget.cx-theme-red input[type="text"],
.cx-widget.cx-theme-red input[type="email"],
.cx-widget.cx-theme-red input[type="tel"],
.cx-widget.cx-theme-red select,
.cx-widget.cx-theme-red textarea {
  background-color: #FDFDFD;
  color: #000000;
  border-color: #94948A;
}
.cx-widget.cx-theme-red .cx-btn-default {
  background: none;
  border-color: #db011c !important;
  color: #db011c;
  background-color: #FDFDFD;
}
.cx-widget.cx-theme-red .cx-btn.cx-disabled {
  background: #CCCCCC;
}
.cx-widget.cx-theme-red .cx-btn-primary {
  color: #FDFDFD;
  border-color: #db011c !important;
  background: #db011c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #db011c 0%, #db011c 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #db011c 0%, #db011c 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #db011c 0%, #db011c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4', endColorstr='@bg_color_5', GradientType=0);
  /* IE6-9 */
  background-color: #db011c;
  /* Old browsers */
}
.cx-widget.cx-theme-red .cx-button-group .cx-btn-primary:focus,
.cx-widget.cx-theme-red .cx-btn-primary:focus,
.cx-widget.cx-theme-red .cx-button-group .cx-btn-default:focus,
.cx-widget.cx-theme-red .cx-btn-default:focus {
  border-color: #000 !important;
}
.cx-widget.cx-theme-red .cx-ac-dropdown-menu {
  background: #FDFDFD;
  border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-red .cx-ac-suggestion:hover,
.cx-widget.cx-theme-red .cx-ac-suggestion.cx-active {
  background-color: #5791FD;
  color: #FDFDFD;
}
.cx-widget.cx-theme-red ::-webkit-scrollbar-thumb {
  background: #939399;
  /* WCAG: Non-text contrast */
}
.cx-widget.cx-theme-red .cx-smokescreen {
  background-color: #FDFDFD;
  opacity: 0.7;
}
.cx-widget.cx-theme-red .cx-dialog-container .cx-dialog {
  background-color: #FDFDFD;
  border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-red .cx-theme-background {
  background-color: #FDFDFD;
}
.cx-widget.cx-theme-red .cx-theme-border {
  border: 1px solid #DAE1E8 !important;
}
.cx-widget.cx-theme-red .cx-theme-text {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
  fill: #5081E1;
}
.cx-widget.cx-theme-red .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
  fill: #34A84F;
}
.cx-widget.cx-theme-red .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
  fill: #F53131;
}
.cx-widget.cx-theme-red .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
  fill: #D47F2F;
}
.cx-widget.cx-theme-red .cx-warning {
  color: #F53131 !important;
}
.cx-widget.cx-theme-red .cx-warning .cx-icon svg * {
  fill: #F53131 !important;
}
.cx-widget.cx-theme-red .cx-countdown {
  color: #000000;
}
.cx-widget.cx-theme-red .cx-countdown .cx-icon svg * {
  fill: #000000;
}
.cx-widget.cx-theme-red .cx-loading-screen .cx-loading-icon svg g g,
.cx-widget.cx-theme-red .cx-loading-container .cx-loading-icon svg g g {
  stroke: #2B74FF;
}
.cx-widget.cx-theme-red .cx-loading-screen .cx-loading-icon svg g:first-child g,
.cx-widget.cx-theme-red .cx-loading-container .cx-loading-icon svg g:first-child g {
  stroke: #72799D;
}
