.search-input {
  .search-input-icons {
    width: auto;
    padding: 0 1rem;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    z-index: 10;
    display: flex;
    
    .clear-button {
      margin-left: .75rem;
      color: $dark-gray !important;
      background: transparent;
      cursor: pointer;
    }

    .help-button {
      margin-left: .75rem;
      color: $dark-gray !important;
      background: transparent;
      cursor: pointer;
    }
  }

  .search-button {
    color: $white;
    background-color: $dark-gray;
    border-color: $dark-gray;
  }
}