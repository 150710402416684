@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker-wrapper {
  @extend .form-control;

  padding: 0 !important;
  border: 0 !important;
  
  .react-datepicker__input-container {
    width: 100%;

    .react-datepicker__close-icon {
      &:after {
        padding: 1px !important;
      }
    }
  }
}
