#wishlist {
  .wishlist-list {
    .wishlist-list-item {
      border-right: none;
      border-bottom: none;
      border-left: none;

      &:first-child {
        border-top: none;
      }

      &:hover {
        z-index: 0;
      }
      
      .wishlist-line {
        img {
          max-height: 150px;
          max-width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  table.wishlists-table {
    table-layout: fixed;
    background: transparent;
    margin-bottom: 0;
    width: 100%;

    thead {
      display: none;
    }

    tr {
      background: #fff;
      border: 1px solid #dfdfdf;
      cursor: pointer;
      display: block;
      padding: 15px 10px;

      &:not(:last-child) {
        border-bottom: none;
      }
    }

    td, th {
      border: 0;
      display: block;
      padding: 0px 0px 20px 40%;
      position: relative;

      &:before {
        color: rgba(0, 0, 0, 0.35);
        text-transform: uppercase;
        font-size: .85em;
        content: attr(data-label);
        display: table-cell;
        font-weight: 500;
        height: 100%;
        left: 15px;
        margin: auto;
        position: absolute;
        vertical-align: middle;
        white-space: nowrap;
        width: 35%;
      }
      
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  #wishlist {
    .wishlist-line {
      .product-image {
        width: 160px;
      }
    }
  }
}