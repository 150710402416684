.loading-dots {
  text-align: left;
  
  > div {
    width: 10px;
    height: 10px;
    background-color: lighten($primary, 50%);
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
      transform: scale(1.0);
    }
}