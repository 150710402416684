.react-select-container {
  max-width: 100%;
  
  &.is-loading {
    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      
      .react-select__indicator {
        svg {
          display: none;
        }
        animation: spinAround .5s infinite linear;
        border: 2px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 1em;
        width: 1em;
        margin-top: 0;
        position: absolute;
        right: .625em;
        top: .625em;
      }
    }
  }

  &.form-control {
    height: auto !important;
    padding: 0;
    border: none;
    background: none !important;

    &.is-invalid {
      border: 1px solid $red;
    }
  }
  
  .react-select__control {
    border-radius: 0;
    
    &.react-select__control--is-focused {
      color: #495057;
      background-color: #ffffff;
      border-color: #fe5d71;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(219, 1, 28, 0.25);
    }
  }
  
  .react-select__menu {
    z-index: 999;
    
    .react-select__menu-list {
      .react-select__option {
        &.react-select__option--is-focused {
          background-color: lighten($primary, 50%);
          cursor: pointer;
        }
        
        &.react-select__option--is-selected {
          background-color: $primary;
        }
      }
    }
  }
}