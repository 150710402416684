#quick-add-modal {
  .modal-title {
    img {
      width: 18px;
      height: 18px;
    }
  }
  
  .modal-body {
    .quantity-input {
      .form-control {
        width: 3em;
        max-width: 3em;
      }
    }
  }
}